import { Routes } from '@angular/router';
import { featureFlagResolver } from 'feature-flag';
import { BaseLangGuard } from 'language';
import { LoadingComponent } from 'loading';
import { UrlPromoResolve } from './landing-pages/resolvers/url-promo.resolve';
import { BaseComponent } from './pages/base/base.component';
import { DummyComponent } from './pages/dummy/dummy.component';
import { PrismicPreviewGuard } from './shared/guards/prismic-preview.guard';

const routes: Routes = [
  {
    path: 'prismic-analysis',
    loadChildren: () => import('./prismic-analysis/prismic-analysis.routes').then(m => m.routes),
  },
  { path: 'auth', loadChildren: () => import('./auth/auth.routes').then(m => m.routes) },
  {
    path: '',
    loadChildren: () => import('./landing-pages/landing-pages.routes').then(m => m.routes),
  },
];

export const appRoutes: Routes = [
  {
    path: 'kitsune-preview',
    component: LoadingComponent,
    canActivate: [PrismicPreviewGuard],
  },
  {
    path: ':lang',
    component: BaseComponent,
    resolve: { promotion: UrlPromoResolve, featureFlags: featureFlagResolver },
    canActivate: [BaseLangGuard],
    children: routes,
  },
  {
    path: '',
    component: DummyComponent,
    canActivate: [BaseLangGuard],
  },
];
