import { isPlatformServer } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { captureException, init, replayIntegration, setTag } from '@sentry/angular';
import { filter, take } from 'rxjs';
import { BRAND_CONFIG } from 'brand';
import { FeatureFlagService } from 'feature-flag';
import { CustomError } from 'utils';
import { release } from './release';
import { SENTRY_ENVIRONMENT } from './sentry.token';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  private brand = inject(BRAND_CONFIG).brand;
  private featureFlags = inject(FeatureFlagService);
  private platformId = inject(PLATFORM_ID);
  private sentryEnvironment = inject(SENTRY_ENVIRONMENT);

  constructor() {
    if (isPlatformServer(this.platformId)) return;
    if (this.sentryEnvironment.isLocal) return;
    this.featureFlags.ready.pipe(filter(Boolean), take(1)).subscribe(() => {
      if (!this.featureFlags.isOn('sentry')) return;
      this.initSentry();
    });
  }

  private initSentry() {
    const environment = this.sentryEnvironment.isProduction ? 'production' : 'development';
    init({
      dsn: 'https://bff26e6b0038aa187257be4228159ff4@o4507967200690176.ingest.de.sentry.io/4507967201869904',
      integrations: [replayIntegration()],
      replaysOnErrorSampleRate: 1.0,
      environment,
      release,
      denyUrls: [
        // Sentry recommendations: https://docs.sentry.io/platforms/javascript/legacy-sdk/tips/
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,

        // Our own custom url filters
        /cdn\.cookielaw\.org/i,
        /www\.googletagmanager\.com/i,
        /t\.contentsquare\.net/i,
        /live-chat-azure-prod18\.sprinklr\.com/i,
      ],
      ignoreErrors: [
        // Sentry recommendations: https://docs.sentry.io/platforms/javascript/legacy-sdk/tips/
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
        // Avast extension error
        '_avast_submit',

        // Our own custom error messages to ignore
        'Unknown Error',
        'Load failed',
        'google_tag_manager',
      ],
    });
    setTag('brand', this.brand);
  }

  captureException(error: CustomError): void {
    captureException(error);
  }
}
