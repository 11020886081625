import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CmsService } from 'prismic';

@Injectable({
  providedIn: 'root',
})
export class PrismicPreviewGuard implements CanActivate {
  private cms = inject(CmsService);
  private router = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = route.queryParams['token'];
    const documentId = route.queryParams['documentId'];

    if (token && documentId) {
      return new Promise((resolve, reject) => {
        this.cms
          .getPreviewRedirectURL()
          .then(redirectUrl => {
            this.router.navigateByUrl(redirectUrl);
            resolve(false);
          })
          .catch(e => reject(e));
      });
    }
    return false;
  }
}
