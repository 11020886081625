import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { tap } from 'rxjs';
import { MonitoringService } from './monitoring.service';

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const monitoringService = inject(MonitoringService);
  return next(req).pipe(
    tap({
      error: error => {
        monitoringService.log(error);
      },
    })
  );
};
