import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { initGoogleTagManager } from 'utils';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (environment.productionMode) {
  enableProdMode();
}

if (!environment.isLocal) {
  initGoogleTagManager(environment.googleTagManagerId);
}

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
