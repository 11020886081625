import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { PromotionService } from 'prismic';
import { ToastService } from 'toast';
import { PrismicService } from '../../services/prismic.service';

@Injectable({
  providedIn: 'root',
})
export class UrlPromoResolve implements Resolve<void> {
  private promotions = inject(PromotionService);
  private toasts = inject(ToastService);
  private prismic = inject(PrismicService);

  /**
   * Checks the url for any promotion code parameters
   * If there are any then it tries to set those promotions as default
   * If the promotion code is not correct, it will show an error toast
   */
  async resolve(route: ActivatedRouteSnapshot) {
    const promoQueryString = route.queryParams.p;
    if (!promoQueryString) return;
    await this.prismic.onToastsLoaded.toPromise();
    const promoCodes: string[] = promoQueryString.split(',');
    const errorText = this.prismic.getTranslation('toasts', 'invalidPromotionCode');
    promoCodes.forEach(
      async promoCode =>
        (await this.promotions.setPromoAsDefault(promoCode)) ||
        this.toasts.add(errorText + ' - ' + promoCode, false, 5000)
    );
  }
}
