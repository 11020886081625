import { ErrorHandler, inject, Injectable } from '@angular/core';
import { MonitoringService } from './monitoring.service';

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandler implements ErrorHandler {
  private monitoringService = inject(MonitoringService);

  public handleError(error: Error): void {
    this.monitoringService.log(error);
    console.error(error);
  }
}
