import { DOCUMENT, isPlatformBrowser, NgClass, NgIf } from '@angular/common';
import {
  APP_ID,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  isDevMode,
  NgZone,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  GuardsCheckEnd,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { ProductCatalogService, SelfcareService } from '@yol-digital/ms-client';
import { filter } from 'rxjs';
import { AnalyticsService, UserDataAnalytics } from 'analytics';
import { UserService } from 'auth-data-access';
import { BrandService } from 'brand';
import { LoadingComponent } from 'loading';
import { MenuGlobalComponent } from 'menu';
import { PrismicLibService } from 'prismic';
import { PCProduct, ProductService, productsPaths, ProductsPaths } from 'product';
import { ToastsComponent } from 'toast';
import { TooltipGlobalComponent } from 'tooltip';
import { appendNoScriptGoogleTagManager, appendScript, BrowserService } from 'utils';
import { environment } from '../environments/environment';
import { AppShellRenderDirective } from './shared/directives/app-shell-render.directive';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [
    ToastsComponent,
    MenuGlobalComponent,
    TooltipGlobalComponent,
    NgClass,
    RouterOutlet,
    NgIf,
    LoadingComponent,
    AppShellRenderDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private router = inject(Router);
  brand = inject(BrandService);
  private userService = inject(UserService);
  private zone = inject(NgZone);
  private matDialog = inject(MatDialog);
  private analyticsService = inject(AnalyticsService);
  private cdr = inject(ChangeDetectorRef);
  private browser = inject(BrowserService);
  private prismicLibService = inject(PrismicLibService);
  private productService = inject(ProductService);
  private platformId = inject(PLATFORM_ID);
  private appId = inject(APP_ID);
  private document = inject<Document>(DOCUMENT);
  loading = true;

  constructor() {
    const platform = isPlatformBrowser(this.platformId) ? 'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${this.appId}`);

    this.router.events.subscribe(resp => {
      if (resp instanceof NavigationStart) {
        this.matDialog.closeAll();
      }
      if (resp instanceof GuardsCheckEnd) this.loading = true;
      if (resp instanceof NavigationEnd) {
        this.loading = false;
        this.setPromoClass(resp);
        if (isPlatformBrowser(this.platformId)) {
          this.zone.runOutsideAngular(() => {
            // Do not scroll to top when NavigationEnd.id is 1 - first page loaded, after hydration
            if (!resp.url.includes('#openModalWithID') && resp.id !== 1) {
              window.scrollTo(0, 0);
            }
          });
        }
      }
      if (resp instanceof NavigationCancel) this.loading = false;
      if (resp instanceof NavigationError) this.loading = false;

      this.cdr.markForCheck();
    });

    //set visitor_id for analytics if there is no visitor_id
    const visitorId = this.userService.getVisitorId();
    if (!visitorId) {
      this.userService.setVisitorId();
    }

    if (isPlatformBrowser(this.platformId)) {
      this.setupAnalytics();
      this.userService.getSubscriptions().subscribe(); // get subscriptions just to set the previously logged in cookie
    } else {
      appendNoScriptGoogleTagManager(this.document, environment.googleTagManagerId);
    }
  }

  ngOnInit() {
    if (isDevMode()) {
      appendScript(this.document, `https://static.cdn.prismic.io/prismic.js?new=true&repo=sunrise-${this.brand.brand}`);
    }
  }

  setPromoClass(e: NavigationEnd) {
    if (/^(\/(en|de|fr|it))?\/blackfriday(\/.+)?/.test(e.urlAfterRedirects)) {
      this.document.body.classList.add('blackfriday');
    } else {
      this.document.body.classList.remove('blackfriday');
    }
  }

  private setupAnalytics() {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(async () => {
      this.analyticsService.addOriginalLocation();

      // get product data for analytics
      const product = await this.getProductFromUrl();

      // get user data for analytics
      const visitorId = this.userService.getVisitorId() as string;
      let userDataAnalytics: UserDataAnalytics = null;
      let userAccount: SelfcareService.AccountResp = null;

      this.userService.getAccount().subscribe({
        next: account => {
          userAccount = account;
          userDataAnalytics = account && this.analyticsService.buildUserData(account, visitorId);
        },
        complete: () => {
          if (userAccount)
            this.analyticsService.pageView({ product, userDataAnalytics, visitorId, account: userAccount });
          else this.analyticsService.pageView({ product, visitorId });
        },
      });
    });
  }

  private async getProductFromUrl(): Promise<PCProduct> {
    const allProducts = await this.prismicLibService.getProducts();
    const allPCProducts = await this.productService.getPCProducts();

    const currentProduct = allProducts.find(item => {
      const path = productsPaths[this.brand.brand as keyof ProductsPaths][item.name.trim().toLowerCase()];
      return path === this.browser.getPathWithoutLanguage();
    });

    return currentProduct
      ? PCProduct.fromMS(
          allPCProducts.find(
            (product: ProductCatalogService.CatalogResponse) => product.code === currentProduct.product_code
          )
        )
      : null;
  }
}
